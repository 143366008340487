<template>
  <div class="main-wrapper">
    <NavbarDashboard></NavbarDashboard>
    <SidebarDashboard></SidebarDashboard>
    <div class="main-content">
      <form @submit.prevent="onSubmit">
        <section class="section">
          <div class="d-flex justify-content-between align-items-center mb-3">
            <div class="">
              <h4 class="title">{{ labelPage }} Item Penggajian</h4>
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link to="/data-item-penggajian"
                      >Item Penggajian
                    </router-link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    {{ labelPage == "Detail" ? "Edit" : "Tambah" }}
                  </li>
                </ol>
              </nav>
            </div>
            <div
              class="button-edit"
              v-show="validated == 1 && labelPage == 'Detail'"
            >
              <button class="btn-add" type="button" @click="editable">
                <img src="/img/icon-edit1.svg" alt="" style="width: 20px" />
                Edit
              </button>
            </div>
            <div v-show="validated != 1 || labelPage == 'Tambah'">
              <button class="btn btn-save" :disabled="isSubmit">
                <span class="fas fa-spinner fa-spin" v-if="isSubmit"></span>
                Simpan
              </button>
              <button class="btn btn-blue ml-2" type="button" v-if="enableSync" @click="onSync" :disabled="isSubmit">
                <span class="fas fa-spinner fa-spin" v-if="isSubmit"></span>
                Simpan & Sync</button>
            </div>
          </div>

          <div class="card info-detail">
            <div class="row">
              <div class="col-12">
                <div class="title">{{ labelPage }} Item Penggajian</div>
                <div class="sub-title">
                  {{ labelPage == "Detail" ? "Edit" : "Tambah" }} informasi yang
                  diperlukan
                </div>
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label for="nama">Nama : </label>
                  <input
                    :disabled="validated == 1 && labelPage == 'Detail'"
                    v-model="formData.nama"
                    :class="{ 'is-invalid': formError && formError.nama }"
                    type="text"
                    class="form-control"
                    id="nama"
                    placeholder="Masukkan Nama Penggajian"
                  />
                  <div class="form-error" v-if="formError && formError.nama">
                    {{ formError.nama }}
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label for="inputAddress2">Tipe Gaji/Tunjangan :</label>
                  <Select2
                    :disabled="validated == 1 && labelPage == 'Detail'"
                    v-model="formData.id_tipe"
                    :class="{ 'is-invalid': formError && formError.id_tipe }"
                    :options="optionTypePayroll"
                    placeholder="Tipe Gaji/Tunjangan"
                    :settings="{ templateResult: tipePayrollResult }"
                    @change="formChange('id_tipe')"
                    @select="formChange('id_tipe')"
                  />
                  <div class="form-error" v-if="formError && formError.id_tipe">
                    {{ formError.id_tipe }}
                  </div>
                </div>

                <!-- <div class="form-group">
                                    <label for="inputAddress2">Bayar/Potong :</label>
                                    <input type="text" class="form-control" id="inputAddress2"
                                        placeholder="Masukkan Bayar/Potong" value="Bulanan" disabled>
                                </div> -->
              </div>

              <div class="col-6">
                <div class="form-group">
                  <label for="inputAddress2">Akun Beban :</label>
                  <div class="form-group">
                    <Select2
                      :disabled="validated == 1 && labelPage == 'Detail'"
                      v-model="formData.beban_akun"
                      :class="{
                        'is-invalid': formError && formError.beban_akun,
                      }"
                      :options="OptionAccountEstimated"
                      placeholder="Pilih akun perkiraan..."
                      :settings="{ templateResult: formatState }"
                      @change="formChange('beban_akun')"
                      @select="formChange('beban_akun')"
                    />
                    <div
                      class="form-error"
                      v-if="formError && formError.beban_akun"
                    >
                      {{ formError.beban_akun }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-6">
                <div class="form-group">
                  <label>Akun Hutang</label>
                  <Select2
                    :disabled="validated == 1 && labelPage == 'Detail'"
                    v-model="formData.akun_hutang"
                    :options="optionDebt"
                    :settings="{ templateResult: formatState }"
                    placeholder="Pilih Akun Hutang"
                    @change="formChange('akun_hutang')"
                    @select="formChange('akun_hutang')"
                    :class="{
                      'is-invalid': formError && formError.akun_hutang,
                    }"
                  />
                </div>
              </div>

              <div class="col-6">
                <div class="form-group">
                  <label for="inputAddress2">Status :</label>
                  <Select2
                    :disabled="validated == 1 && labelPage == 'Detail'"
                    v-model="formData.status"
                    :options="statusCompany"
                    :class="{ 'is-invalid': formError && formError.status }"
                    placeholder="Status"
                    :settings="{ minimumResultsForSearch: -1 }"
                    @change="formChange('status')"
                    @select="formChange('status')"
                  />
                  <div class="form-error" v-if="formError && formError.status">
                    {{ formError.status }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </form>
    </div>
  </div>
  <div>
    <router-view></router-view>
  </div>
</template>
<script>
// import FooterDashboard from '../src/components/Footer.vue'
import Select2 from "vue3-select2-component";
import SidebarDashboard from "../../../components/Sidebar.vue";
import NavbarDashboard from "../../../components/Navbar.vue";
import { loadScript } from "vue-plugin-load-script";

import $ from "jquery";
import {
  get_PayrollItemDetail,
  get_PayrollKategoriList,
  post_PayrollItemSave,
} from "../../../actions/setting";
import { get_AkunList } from "../../../actions/akun_perkiraan";
import { checkRules, cksClient, showAlert, checkApp } from "../../../helper";
loadScript("/assets/js/stisla.js");
loadScript("/assets/js/scripts.js");
loadScript("/assets/js/custom.js");
loadScript("/assets/js/page/index.js");

export default {
  name: "DashboardLayout",
  components: {
    // FooterDashboard,
    SidebarDashboard,
    NavbarDashboard,
    Select2,
  },

  data() {
    return {
      enableSync: checkApp('rawuh') ? true : false,
      validated: 1,
      myValue: "aaaa",
      statusCompany: [
        { id: "aktif", text: "Aktif" },
        { id: "tidak", text: "Nonaktif" },
      ], // or [{id: key, text: value}, {id: key, text: value}]
      optionTypePayroll: [],
      optionDebt: [],
      OptionAccountEstimated: [],
      labelPage: this.$route.params.id ? "Detail" : "Tambah",
      id_item: this.$route.params.id ? this.$route.params.id : "",
      id_tipe: "",
      formData: {
        id: this.$route.params.id ? this.$route.params.id : "",
        id_company: cksClient().get("_account").id_company,
        id_tipe: "",
        nama: "",
        status: "aktif",
        beban_akun: "",
        akun_hutang: "",
        isSync: false,
      },
      rules: {
        id_tipe: {
          required: true,
        },
        nama: {
          required: true,
        },
        status: {
          required: true,
        },
        beban_akun: {
          required: true,
        },
        akun_hutang: {
          required: true,
        },
      },
      formError: [],
      id_company: cksClient().get("_account").id_company,
      isSubmit: false,
    };
  },
  created() {
    this.getTipePayroll();
    this.getAkun();
    this.getDebtAkun();
    if (this.id_item) {
      this.getData();
    }
  },
  methods: {
    editable() {
      this.validated = 2;
    },
    getData() {
      get_PayrollItemDetail(
        this.id_item,
        (res) => {
          const { data } = res;
          if (data) {
            this.formData = { ...this.formData, ...data };
            this.id_tipe = this.formData.id_tipe;
            delete this.formData.created_at;
            delete this.formData.updated_at;
          } else {
            showAlert(this.$swal, {
              title: "ERROR!",
              msg: "Data Not Found",
              showCancelButton: true,
              showConfirmButton: false,
              onCancel: () => {
                this.$router.push({
                  name: "PayrollItems",
                });
              },
            });
          }
        },
        () => {
          showAlert(this.$swal, {
            title: "ERROR!",
            msg: "Data Not Found",
            showCancelButton: true,
            showConfirmButton: false,
            onCancel: () => {
              this.$router.push({
                name: "PayrollItems",
              });
            },
          });
        }
      );
    },

    tipePayrollResult(val) {
      if (
        (val.id == "16" && val.jumlah > 1) ||
        (val.id == "17" && val.jumlah > 1)
      ) {
        return null;
      } else {
        if (
          (val.id == "16" && this.id_tipe != "16" && val.jumlah == 1) ||
          (val.id == "17" && this.id_tipe != "17" && val.jumlah == 1)
        ) {
          return null;
        } else {
          return val.text;
        }
      }
    },
    getAkun() {
      get_AkunList(
        {
          id_company: this.id_company,
        },
        (res) => {
          const { list } = res;
          this.OptionAccountEstimated = [];
          for (const key in list) {
            const element = list[key];
            this.OptionAccountEstimated.push({
              id: element.id,
              text: `${element.nama}`,
              no: element.id,
              type: element.tipe,
              sub_level: parseInt(element.sub_level),
              disabled:
                parseInt(element.max_subLevel) === parseInt(element.sub_level)
                  ? false
                  : true,
            });
          }
        }
      );
    },

    getDebtAkun() {
      get_AkunList(
        {
          id_company: this.id_company,
          tipe: "8,14,15",
        },
        (res) => {
          var { list } = res;
          var data = [];
          for (const key in list) {
            if (Object.hasOwnProperty.call(list, key)) {
              const element = list[key];
              data.push({
                id: element.id,
                text: `${element.nama}`,
                no: element.id,
                type: element.tipe,
                sub_level: parseInt(element.sub_level),
                disabled:
                  parseInt(element.max_subLevel) === parseInt(element.sub_level)
                    ? false
                    : true,
              });
            }
          }
          this.optionDebt = data;
        }
      );
    },

    getTipePayroll() {
      get_PayrollKategoriList((res) => {
        const { list } = res;
        this.optionTypePayroll = [];
        for (const key in list) {
          const element = list[key];
          this.optionTypePayroll.push({
            id: element.id,
            text: element.nama,
            jumlah: element.jumlah ? element.jumlah : 0,
          });
        }
      });
    },
    async formChange(key) {
      if (this.rules[key]) {
        this.rules[key].changed = true;
      }
      let check = await checkRules(this.rules, this.formData);
      this.formError = check.error;
    },
    formatState(state) {
      if (!state.id) {
        return state.text;
      }
      var padding =
        state.sub_level == 2 ? "pl-3" : state.sub_level == 1 ? "pl-2" : "";
      var $state = $(
        '<div class="' +
          padding +
          '">' +
          state.text +
          '<div class="noEstimated" style="font-size: 12px;color: #898989; display:flex; justify-content: space-between;">' +
          state.no +
          "<div>" +
          state.type +
          "</div></div></div>"
      );
      return $state;
    },

    postData() {
      this.isSubmit = true;
      post_PayrollItemSave(
        this.formData,
        (res) => {
          this.isSubmit = false;
          var msg =
            res.response_code === 201
              ? "Data berhasil disimpan"
              : "Data berhasil diperbarui";
          showAlert(this.$swal, {
            title: "BERHASIL!",
            msg: msg,
            onSubmit: () => {
              this.$router.push({
                name: "PayrollItems",
              });
            },
          });
        },
        () => {
          this.isSubmit = false;
          showAlert(this.$swal, {
            title: "GAGAL!",
            msg: "Terjadi Kesalahan, silahkan ulangi kembali",
            showCancelButton: true,
            showConfirmButton: false,
          });
        }
      );
    },
    async onSubmit() {
      for (const key in this.rules) {
        if (Object.hasOwnProperty.call(this.rules, key)) {
          this.rules[key].changed = true;
        }
      }
      let check = await checkRules(this.rules, this.formData);
      this.formError = check.error;
      if (!this.id_item) {
        delete this.formData.id;
      }
      if (check.success) {
        this.formData.isSync = false
        showAlert(this.$swal, {
          title: "PERHATIAN!",
          msg: this.formData.id
            ? "Apakah anda yakin, Tindakan ini akan menyimpan semua perubahan yang anda lakukan?"
            : "Apakah anda yakin, semua data yang telah anda isikan akan tersimpan?",
          showCancelButton: true,
          cancelButtonText: "Batal",
          confirmButtonText: "Ya, Lanjutkan",
          onSubmit: () => {
            this.postData();
          },
        });
      }
    },

    async onSync() {
      for (const key in this.rules) {
        if (Object.hasOwnProperty.call(this.rules, key)) {
          this.rules[key].changed = true;
        }
      }
      let check = await checkRules(this.rules, this.formData);
      this.formError = check.error;
      if (!this.id_item) {
        delete this.formData.id;
      }
      if (check.success) {
        this.formData.isSync = true
        showAlert(this.$swal, {
          title: "PERHATIAN!",
          msg: this.formData.id
            ? "Apakah anda yakin, Tindakan ini akan menyimpan semua perubahan yang anda lakukan?"
            : "Apakah anda yakin, semua data yang telah anda isikan akan tersimpan?",
          showCancelButton: true,
          cancelButtonText: "Batal",
          confirmButtonText: "Ya, Lanjutkan",
          onSubmit: () => {
            this.postData();
          },
        });
      }
    },
  },
};
</script>

<style scoped src="../../../assets/css/style.css"></style>

<style scoped src="../../../assets/css/custom.css"></style>

<style scoped>
.card .card-title {
  font-size: 18px;
  color: #000;
  font-weight: 500;
}

.card {
  border: 1px solid #eaeaea;
  border-radius: 10px;
}
</style>
