import {API} from '../../constans/Url'
import {apiClient} from '../../helper'

//START ITEM PENOMORAN
const get_CompanySettingList = (params, callbackSuccess, callbackError) => {
  apiClient(true).get(API.COMPANY.SETTING.LIST, params).then(res => {
    if (res.status == 200) {
      callbackSuccess(res.data)
    }else {
      callbackError(res.data)
    }
  }).catch(err =>{
    callbackError(err)
  })
}

const get_CompanySettingDetail = (id, callbackSuccess, callbackError) => {
  apiClient(true).get(API.COMPANY.SETTING.GET, {id: id}).then(res => {
    if (res.status == 200) {
      callbackSuccess(res.data)
    }else {
      callbackError(res.data)
    }
  }).catch(err =>{
    callbackError(err)
  })
}

const post_CompanySettingSave = (params, callbackSuccess, callbackError) => {
  apiClient(true).post(API.COMPANY.SETTING.SAVE, params).then(res => {
    if (res.status == 200 || res.status === 201) {
      callbackSuccess(res.data)
    }else {
      callbackError(res.data)
    }
  }).catch(err =>{
    callbackError(err)
  })
}


//END ITEM PENOMORAN

//START PAYROLL ITEM
const get_PayrollItemList = (params, callbackSuccess, callbackError) => {
  apiClient(true).get(API.PAYROLL.ITEM.LIST, params).then(res => {
    if (res.status == 200) {
      callbackSuccess(res.data)
    }else {
      callbackError(res.data)
    }
  }).catch(err =>{
    callbackError(err)
  })
}

const get_PayrollItemDetail = (id, callbackSuccess, callbackError) => {
  apiClient(true).get(API.PAYROLL.ITEM.GET, {id: id}).then(res => {
    if (res.status == 200) {
      callbackSuccess(res.data)
    }else {
      callbackError(res.data)
    }
  }).catch(err =>{
    callbackError(err)
  })
}

const post_PayrollItemSave = (params, callbackSuccess, callbackError) => {
  apiClient(true).post(API.PAYROLL.ITEM.SAVE, params).then(res => {
    if (res.status == 200 || res.status === 201) {
      callbackSuccess(res.data)
    }else {
      callbackError(res.data)
    }
  }).catch(err =>{
    callbackError(err)
  })
}
//END PAYROLL ITEM

//START PAYROLL KATEGORI
const get_PayrollKategoriList = (callbackSuccess, callbackError) => {
  apiClient(true).get(API.PAYROLL.KATEGORI.LIST).then(res => {
    if (res.status == 200) {
      callbackSuccess(res.data)
    }else {
      callbackError(res.data)
    }
  }).catch(err =>{
    callbackError(err)
  })
}
//END PAYROLL KATEGORI

export{
  get_CompanySettingList,
  post_CompanySettingSave,
  get_CompanySettingDetail,
  get_PayrollItemList,
  get_PayrollItemDetail,
  post_PayrollItemSave,
  get_PayrollKategoriList
}